import { render, staticRenderFns } from "./svg.vue?vue&type=template&id=3d25c53e&"
import script from "./svg.vue?vue&type=script&lang=js&"
export * from "./svg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/amazon-demosequesteco/demo.sequest.eco/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d25c53e')) {
      api.createRecord('3d25c53e', component.options)
    } else {
      api.reload('3d25c53e', component.options)
    }
    module.hot.accept("./svg.vue?vue&type=template&id=3d25c53e&", function () {
      api.rerender('3d25c53e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/svg/svg.vue"
export default component.exports